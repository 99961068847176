/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useStaticQuery, graphql, Link } from 'gatsby'

// Language
import { getLanguage } from 'services/language'
import LanguageSwitch from 'components/elements/LanguageSwitch'

// Elements
import BlockDefault from 'components/elements/BlockDefault'
import ParseContent from 'components/shared/ParseContent'
import ImageBackground from 'components/elements/ImageBackground'

// Images
import menuBackground from 'img/menu.jpg'

// Icons
import Logo from 'img/logo.inline.svg'
import Phone from 'img/icon/phone.inline.svg'
import Mail from 'img/icon/mail.inline.svg'

// Socials
import Facebook from 'img/icon/facebook.inline.svg'
import LinkedIn from 'img/icon/linkedin.inline.svg'
import Twitter from 'img/icon/twitter.inline.svg'
import ButtonAnimateTruck from '../elements/ButtonAnimateTruck'
import MegaSubMenu from './MegaSubmenu'
import SubmenuMobile from './SubmenuMobile'

const StyledHeader = styled.nav`
  background-color: ${props => props.theme.color.contrast};
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 10;

  @media (min-width: 992px) {
    height: 110px;
  }

  @media (max-width: 991px) {
    height: 95px;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Top = styled.div`
  font-weight: ${props => props.theme.font.weight.s};
  color: ${props => props.theme.color.text.light};
`

const SocialLink = styled.a`
  font-size: 0;
`

const SocialIcon = styled.div`
  & > svg {
    height: 35px;
    width: 35px;
  }
`

const Button = styled.a`
  background-color: ${props => props.theme.color.light};
  height: 35px;
  display: flex;
  align-items: center;
  border-radius: 17px;
  padding: 0 17px;

  & svg {
    height: 20px;
    width: 20px;
  }

  & > span {
    color: ${props => props.theme.color.text.secondary};
    font-weight: ${props => props.theme.font.weight.l};

    &:hover {
      text-decoration: underline;
    }
  }
`

const Bottom = styled(Container)`
  position: absolute;
  left: 0;
  right: 0;

  & > div {
    background-color: ${props => props.theme.color.light};
    width: 100%;
    box-shadow: 0 0 15px rgba(0, 0, 0, .3);

    @media (min-width: 992px) {
      height: ${props => props.isSticky ? 80 : 100}px;
      border-radius: 30px;
    }

    @media (max-width: 991px) {
      height: 80px;
      border-radius: 20px;
    }
  }

  @media (min-width: 992px) {
    bottom: ${props => props.isSticky ? -40 : -60}px;
  }

  @media (max-width: 991px) {
    bottom: -40px;
  }
`

const LogoLink = styled(Link)`
  font-size: 0;

  @media (min-width: 992px){
    width: 25%;
  }
`

const StyledLogo = styled(Logo)`
  @media (min-width: 992px) {
    height: 45px;
    width: 196px;
  }

  @media (max-width: 991px) {
    height: 35px;
    width: 152px;
  }
`

const Header = ({ isSticky, setIsScrollable }) => {
  const language = getLanguage()

  const {
    headerNL,
    headerEN,
  } = useStaticQuery(graphql`
    {
      headerNL: wordpressWpComponenten(
        slug: { eq: "header-new" }
        wpml_current_locale: { eq: "nl_NL" }
      ) {
        ...headerContent
      }

      headerEN: wordpressWpComponenten(
        slug: { eq: "header-new-en" }
        wpml_current_locale: { eq: "en_US" }
      ) {
        ...headerContent
      }
    }

    fragment headerContent on wordpress__wp_componenten {
      acf {
        top {
          description
          phonenumber {
            title
            url
          }
          email_address {
            title
            url
          }
          socials {
            title
            url
          }
        }
        bottom {
          links {
            page {
              title
              url
              target
            }
            submenuDestinations: submenu_destinations {
              link {
                title
                url
              }
            }
            submenuServices: submenu_services {
              link {
                title
                url
              }
            }
          }
        }
        infographic {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 500)
              }
            }
          }
          description 
          button {
            localFile {
              publicURL
            }
          }
        }
        menuMobile: menu_mobile {
          link {
            title
            url
          }
        }
      }
    }
  `)

  const headerContent = language === 'nl_NL' ? headerNL : headerEN

  return (
    <StyledHeader isSticky={isSticky}>
      <Top>
        <Container className="container py-2 py-lg-3">
          <div className="d-lg-flex align-items-center d-none font-weight-s">
            {headerContent.acf.top.description}
          </div>
          <div className="d-flex align-items-center">
            <LanguageSwitch />
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <Button href={headerContent.acf.top.phonenumber.url}>
              <Phone className="mr-xl-3" />
              <span style={{ fontSize: 0 }}>
                {headerContent.acf.top.phonenumber.title}
              </span>
              <span className="d-xl-inline-block d-none">
                {headerContent.acf.top.phonenumber.title}
              </span>
            </Button>
            <Button href={headerContent.acf.top.email_address.url} className="ml-2">
              <Mail className="mr-xl-3" />
              <span style={{ fontSize: 0 }}>
                {headerContent.acf.top.email_address.title}
              </span>
              <span className="d-xl-inline-block d-none">
                {headerContent.acf.top.email_address.title}
              </span>
            </Button>
            {headerContent.acf.top.socials.map(({ title, url }) => {
              let icon = null

              switch (title) {
                case 'Twitter':
                  icon = <Twitter />
                  break;

                case 'LinkedIn':
                  icon = <LinkedIn />
                  break;

                default:
                  icon = <Facebook />
                  break;
              }

              return (
                <SocialLink href={url} target="_blank" rel="noreferrer noopener" key={title} className="ml-2 d-sm-flex d-none">
                  <SocialIcon>
                    {icon}
                  </SocialIcon>
                  {title}
                </SocialLink>
              )
            })}
          </div>
        </Container>
      </Top>
      <Bottom className="container" isSticky={isSticky}>
        <Container className="px-4 px-lg-5">
          {language === 'nl_NL' ? (
            <LogoLink to="/">
              <StyledLogo />
              Home
            </LogoLink>
          ) : (
            <LogoLink to="/en/">
              <StyledLogo />
              Home
            </LogoLink>
          )}
          <MenuDesktop menu={headerContent.acf.bottom} infographic={headerContent.acf.infographic} />
          <MenuMobile menu={headerContent.acf.menuMobile} headerContent={headerContent.acf.bottom} setIsScrollable={setIsScrollable} />
        </Container>
      </Bottom>
    </StyledHeader>
  )
}

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  
  @media (min-width: 992px){
    width: 75%;
    justify-content: space-between;
  }
`

const MenuDesktop = ({ menu, infographic }) => (
  <List className="navbar-items d-none d-lg-flex h-100 align-items-center">
    <Menu menu={menu} infographic={infographic} />
  </List>
)

const NavButton = styled.div`
  top: 15px;
  right: 10px;
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
`

const NavButtonStripe = styled.div`
  background-color: ${props => props.theme.color.text.main};
  height: 3px;
  width: 50%;
  margin: 5px auto;
`

const NavButtonTitle = styled.div`
  color: ${props => props.theme.color.text.main};
  text-transform: uppercase;
`

const MenuWrapper = styled.div`
  background-color: ${props => props.theme.color.light};
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  padding: 30px 0 0;
  overflow-y: scroll;

  & ul {
    padding: 0;
    list-style-type: none;
    text-align: center;
    width: 60%;
    margin: 75px auto 35px;
    overflow-y: scroll;

    @media (max-width: 991px){
      width: 80%;
    }

    & li {
      padding: 0;

      & a {
        color: ${props => props.theme.color.text.light};
        font-size: ${props => props.theme.font.size.l};
        border-bottom: 1px solid #f1f1f1;
        display: block;
        padding: 10px 0;

        @media (max-width: 991px){
          padding: 15px 0;
        }
      }

      &:last-child a {
        border-bottom: none;
      }
    }
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
`

const MenuBackground = styled(ImageBackground)`
  position: absolute !important;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  height: 110%;
`

const MenuBackgroundOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, .7);
`

const MenuBackgroundContent = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before, &:after {
    background-color: ${props => props.theme.color.text.light};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const NavButtonCloseTitle = styled.div`
  color: ${props => props.theme.color.text.light};
  text-transform: uppercase;
  margin-top: 3px;
`

const MenuMobile = ({ menu, setIsScrollable, headerContent }) => {
  const [isOpen, setIsOpen] = useState(false)

  const language = getLanguage()

  return (
    <div className="d-block d-lg-none">
      <NavButton
        role="button"
        onClick={() => {
          setIsOpen(!isOpen)
          setIsScrollable(false)
        }}
      >
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonTitle>
          Menu
        </NavButtonTitle>
      </NavButton>
      {isOpen && (
        <MenuWrapper isOpen={isOpen}>
          <MenuBackground src={menuBackground} alt="Home">
            <MenuBackgroundOverlay />
            <MenuBackgroundContent>
              <NavButtonClose
                role="button"
                onClick={() => {
                  setIsOpen(!isOpen)
                  setIsScrollable(true)
                }}
              >
                <NavButtonCloseCross />
                <NavButtonCloseTitle>
                  {/* {language === 'nl_NL' ? (
                    <span>Sluiten</span>
                  ) : (
                    <span>Close</span>
                  )} */}
                </NavButtonCloseTitle>
              </NavButtonClose>
              <ul>
                <MobileMenu menu={menu} headerContent={headerContent} />
                {language === 'nl_NL' ? (
                  <Item>
                    <ButtonAnimateTruck to="/offerte">
                      Prijsindicatie
                    </ButtonAnimateTruck>
                  </Item>
                ) : (
                  <Item>
                    <ButtonAnimateTruck to="/en/quotation">
                      Price indication
                    </ButtonAnimateTruck>
                  </Item>
                )}
              </ul>
            </MenuBackgroundContent>
          </MenuBackground>
        </MenuWrapper>
      )}
    </div>
  )
}

const Item = styled(motion.li)`

    @media (max-width: 991px){  
      width: 100%;
    }

  & > a {
    font-weight: ${props => props.theme.font.weight.s};
    color: ${props => props.theme.color.text.main};

    @media (max-width: 991px){
      width: 100%;
    }

    &:hover {
      text-decoration: underline;
    }

    &[aria-current] {
      font-weight: ${props => props.theme.font.weight.l};
      color: ${props => props.theme.color.text.contrast};
    }
  }
`

const Submenu = styled(motion.div)`
  position: absolute;
  top: 100%;
  left: 0%;
  z-index: 2;

  & > div {
    background-color: ${props => props.theme.color.contrast};
    color: ${props => props.theme.color.text.light};
    padding: 35px !important;
    min-width: 550px;
  }
`

const SubmenuContent = styled(ParseContent)`
  text-align: inherit;

  & a {
    color: ${props => props.theme.color.text.light};
    font-weight: ${props => props.theme.font.weight.s};
    font-size: ${props => props.theme.font.size.sm};

    &:hover {
      text-decoration: underline;
    }
  }

  & table {
    & td {
      line-height: ${props => props.theme.font.size.xm};
      padding: 5px;
      vertical-align: top;
    }

    & thead td {
      font-weight: ${props => props.theme.font.weight.l};
      border-bottom: 1px solid ${props => props.theme.color.text.light};
    }

    /* & tbody td {
      border-bottom: 1px solid #9e2121;
    } */
  }
`

const MobileMenu = ({ menu, headerContent }) => menu.map(({ link }) => {
  const [isOpen, setIsOpen] = useState(false)
  const language = getLanguage()

  return (
    <Item
      initial="rest"
      whileHover="hover"
      animate="rest"
      key={link.url}
      className="ml-xl-3 ml-lg-2 h-100 d-flex align-items-center"
    >
      {link.title === 'Onze diensten' ? (
        <>
          <a href="#" onClick={() => setIsOpen(isOpen => !isOpen)}>{link.title}</a>
          <SubmenuMobile isOpen={isOpen} items={headerContent} setIsOpen={setIsOpen} language={language} />
        </>
      ) : link.title === 'Our services' ? (
        <>
          <a href="#" onClick={() => setIsOpen(isOpen => !isOpen)}>{link.title}</a>
          <SubmenuMobile isOpen={isOpen} items={headerContent} setIsOpen={setIsOpen} language={language} />
        </>
      ) : (
        <Link to={link.url}>{link.title}</Link>
      )}
    </Item>
  )
})


const Menu = ({ menu, infographic }) => menu.links.map(({ page, submenuDestinations, submenuServices }) => {
  const language = getLanguage()

  return (
    <Item
      initial="rest"
      whileHover="hover"
      animate="rest"
      key={page.url}
      className="ml-xl-3 ml-lg-2 h-100 d-flex align-items-center"
    >
      <Link to={page.url || '/'} target={page.target}>{page.title}</Link>
      {language === 'nl_NL' && page.title === "Internationaal verhuizen" && (
        <MegaSubMenu destinations={submenuDestinations} services={submenuServices} language={language} infographic={infographic} />
      )}
      {language !== 'nl_NL' && page.title === "International removals" && (
        <MegaSubMenu destinations={submenuDestinations} services={submenuServices} infographic={infographic} language={language} />
      )}
      {/* {submenu && submenu.length > 0 && (
          <Submenu
            variants={{
              rest: {
                opacity: 0,
                y: -10,
                display: 'none'
              },
              hover: {
                opacity: 1,
                y: 0,
                display: 'block'
              }
            }}
          >
            <BlockDefault>
              <SubmenuContent content={submenu} />
            </BlockDefault>
          </Submenu>
        )} */}
    </Item>
  )
})

export default Header
