import React from 'react'
import styled from 'styled-components'
import ImageBackground from './ImageBackground'
import Plaatjie from '@ubo/plaatjie'


const Hero = styled.div`
  width: 100%;
  max-width: 1550px;
  margin: 0 auto;
  position: relative;

  ${props => props.rounded && `
    @media (min-width: 1550px) {
      border-bottom-left-radius: 35px;
      border-bottom-right-radius: 35px;
      overflow: hidden;
    }
  `};

  @media (min-width: 1200px) {
    height: 400px;
  }

  @media (max-width: 1199px) {
    height: 350px;
  }

  @media (max-width: 991px) {
    height: 250px;
  }
`

const HeroImage = styled(Plaatjie)`
  position: absolute !important;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
`

const HeroAlt = ({ image, children, small, rounded = true  }) => (
  <Hero small={small} rounded={rounded}>
    <HeroImage image={image} alt="" />
    <div className="container position-relative w-100 h-100 pt-lg-4">
      {children}
    </div>
  </Hero>
)

export default HeroAlt