import React from 'react'
import styled from 'styled-components'

const Block = styled.div`
  background-color: ${props => props.theme.color.light};
  border-radius: ${props => props.bigBorderRadius ? 50 : 35}px;
  box-shadow: 0 0 15px rgba(0, 0, 0, .3);
  position: relative;
  z-index: 9;
`

const BlockDefault = ({ className, children, bigBorderRadius }) => (
  <Block className={`p-4 p-lg-5 ${className}`} bigBorderRadius={bigBorderRadius}>
    {children}
  </Block>
)

export default BlockDefault