import React from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

const mainTheme = {
  color: {
    main: '#1D3660',
    contrast: '#CE1635',
    secondary: '#109820',
    light: '#ffffff',
    background: '#C5C5C5',

    text: {
      main: '#6B6B6B',
      contrast: '#CE1635',
      secondary: '#109820',
      light: '#ffffff',
      grey: '#777777',
    },
  },

  font: {
    family: {
      main: 'Nunito, sans-serif',
      secondary: 'Verdana, Arial',
    },

    size: {
      xxs: '8px',
      xs: '10px',
      s: '12px',
      sm: '14px',
      m: '16px',
      18: '18px',
      xm: '20px',
      l: '24px',
      xl: '30px',
      35: '35px',
      xxl: '45px',
      50: '50px',
      xxxl: '70px',
    },

    weight: {
      xs: 200,
      s: 300,
      m: 400,
      l: 600,
      700: 700,
      xl: 800,
    }
  }
}

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
    overflow: ${props => props.isScrollable ? 'initial' : 'hidden'};
  }

  body {
    font-family: ${props => props.theme.font.family.main};
    font-size: ${props => props.theme.font.size.m};
    color: ${props => props.theme.color.text.main};
  }

  ${props => Object.keys(props.theme.color).map(key => `.color-background-${key} { background-color: ${props.theme.color[key]}; }`)}

  ${props => Object.keys(props.theme.color.text).map(key => `.color-${key} { color: ${props.theme.color.text[key]}; }`)}

  ${props => Object.keys(props.theme.font.family).map(key => `.font-family-${key} { font-family: ${props.theme.font.family[key]}; }`)}

  ${props => Object.keys(props.theme.font.size).map(key => `.font-size-${key} { font-size: ${props.theme.font.size[key]}; }`)}

  ${props => Object.keys(props.theme.font.weight).map(key => `.font-weight-${key} { font-weight: ${props.theme.font.weight[key]}; }`)}

  #rcc-confirm-button {
    background-color: ${props => props.theme.color.contrast} !important;
    color: ${props => props.theme.color.text.light} !important;
  }

  @media (min-width: 992px) {
    h1 {
      font-size: ${props => props.theme.font.size.xxl};
    }

    h2 {
      font-size: ${props => props.theme.font.size.xl};
    }

    h3 {
      font-size: ${props => props.theme.font.size.l};
    }
  }

  @media (max-width: 991px) {
    h1 {
      font-size: ${props => props.theme.font.size.xl} !important;
    }

    h2 {
      font-size: ${props => props.theme.font.size.l} !important;
    }

    h3 {
      font-size: ${props => props.theme.font.size.xm};
    }
  }

  button {
    -webkit-appearance: none;
  }
  
  .react-datepicker-wrapper {
    width: 100%;
  }
`

const Theme = ({ children, isScrollable }) => (
  <ThemeProvider theme={mainTheme}>
    <GlobalStyle isScrollable={isScrollable} />
    {children}
  </ThemeProvider>
)

export default Theme
