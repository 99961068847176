import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'
import ParseContent from 'components/shared/ParseContent'
import ButtonAnimateTruck from '../elements/ButtonAnimateTruck'
import ButtonSecondary from '../elements/ButtonSecondary'

const StyledMegaSubMenu = styled(motion.div)`
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 9999;

  & > div {
    background-color: ${(props) => props.theme.color.contrast};
    color: ${(props) => props.theme.color.text.light};
    padding: 50px !important;
    display: flex;
    width: 100%;
    border-radius: 30px;
  }
`

const StyledUL = styled.ul`
  list-style: none;
  margin-left: -2.6rem;
`

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.color.text.light} !important;
`

const CustomStyledLink = styled.p`
  color: ${(props) => props.theme.color.text.light} !important;
`

const Box = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 4rem 2rem 2rem;
  color: #000;
  margin-top: -5rem;

  @media (max-width: 1024px) {
    padding: 4rem 1rem 2rem;
  }
`

const BoxContent = styled(ParseContent)`
  & h3 {
    color: #000;
    font-size: 20px;
    line-height: 26px;
    font-weight: bold;

    @media (max-width: 1024px) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  & span {
    color: #6b6b6b;
    font-size: 16px;
    font-weight: 300;
  }
`

const StyledButton = styled.a`
  background-color: ${(props) => props.theme.color.light};
  border: 3px solid ${(props) => props.theme.color.text.secondary};
  display: inline-block;
  border-radius: 30px;
  font-weight: ${(props) => props.theme.font.weight.l};
  color: ${(props) => props.theme.color.text.secondary};
  width: 100%;
  height: 100%;
  display: block;

  & > svg {
    margin-left: 15px;

    @media (min-width: 992px) {
      height: 25px;
      width: 25px;
    }

    @media (max-width: 991px) {
      height: 20px;
      width: 20px;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.color.secondary};
    color: ${(props) => props.theme.color.text.light};
  }

  @media (min-width: 992px) {
    font-size: ${(props) =>
      props.small ? props.theme.font.size.m : props.theme.font.size.xm};
    padding: 12px 35px;
  }

  @media (max-width: 991px) {
    font-size: ${(props) =>
      props.small ? props.theme.font.size.m : props.theme.font.size.m};
    padding: 10px 25px;
  }
`

const Image = styled(Plaatjie)`
  width: 225px;
  display: block;
  margin: 0 auto;
  position: relative;
  top: -25px;
`

const MegaSubMenu = ({ destinations, services, language, infographic }) => (
  <StyledMegaSubMenu
    variants={{
      rest: {
        opacity: 0,
        y: -10,
        display: 'none',
      },
      hover: {
        opacity: 1,
        y: 0,
        display: 'flex',
      },
    }}
  >
    <div>
      <div className="col-lg-6">
        <p>
          {language === 'nl_NL' ? (
            <strong>Populaire bestemmingen</strong>
          ) : (
            <strong>Top destinations</strong>
          )}
        </p>
        <div className="d-flex mb-3">
          <div className="col-lg-6 px-0">
            <StyledUL>
              {destinations.slice(0, 5).map((item) => (
                <li className="mb-1">
                  <StyledLink to={item.link.url}>{item.link.title}</StyledLink>
                </li>
              ))}
            </StyledUL>
          </div>
          <div className="col-lg-6 px-0">
            <StyledUL>
              {destinations.slice(5, 10).map((item) => (
                <li className="mb-1">
                  <StyledLink to={item.link.url}>{item.link.title}</StyledLink>
                </li>
              ))}
            </StyledUL>
          </div>
        </div>
        <p>
          {language === 'nl_NL' ? (
            <>
              <CustomStyledLink className="mb-4 d-block">
                <strong>
                  <StyledLink to="/meer-landen/">
                    Bekijk meer landen.
                  </StyledLink>
                </strong>
              </CustomStyledLink>
              <div className="col-md-11 col-xl-9 px-0">
                <p className="mb-3">
                  <em>Of vraag een prijsindicatie aan</em>
                </p>
                <ButtonAnimateTruck to="/offerte/">
                  Ik wil een prijsindicatie
                </ButtonAnimateTruck>
              </div>
            </>
          ) : (
            <>
              <CustomStyledLink className="mb-3 d-block">
                <strong>
                  <StyledLink to="/en/more-countries/">
                    View more countries.
                  </StyledLink>
                </strong>
              </CustomStyledLink>
              <div className="col-md-11 col-xl-9 px-0">
                <p className="mb-3">
                  <em>Or request a price indication</em>
                </p>
                <ButtonAnimateTruck to="/en/quotation/">
                  I want a price indication
                </ButtonAnimateTruck>
              </div>
            </>
          )}
        </p>
      </div>
      <div className="col-lg-2">
        <p>
          <strong>Services</strong>
        </p>
        <StyledUL>
          {services.map((service) => (
            <li className="mb-1">
              <StyledLink to={service.link.url}>
                {service.link.title}
              </StyledLink>
            </li>
          ))}
        </StyledUL>
      </div>
      {language === 'nl_NL' && (
        <div className="col-lg-4">
          <Image image={infographic.image} alt="Download infographic" />
          <Box>
            <BoxContent content={infographic.description} />
            <div className="d-flex align-items-center justify-content-center my-3">
              <svg
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M23 3.83332H0V19.1668H23V3.83332Z" fill="#F0F0F0" />
                <path
                  d="M12.9375 3.83318H10.0625V10.0623H0V12.9373H10.0625V19.1665H12.9375V12.9373H23V10.0623H12.9375V3.83318Z"
                  fill="#D80027"
                />
                <path
                  d="M17.6896 14.1665L23 17.1168V14.1665H17.6896Z"
                  fill="#0052B4"
                />
                <path
                  d="M14 14.1665L23 19.1665V17.7526L16.545 14.1665H14Z"
                  fill="#0052B4"
                />
                <path
                  d="M20.6027 19.1665L14 15.498V19.1665H20.6027Z"
                  fill="#0052B4"
                />
                <path
                  d="M14 14.1665L23 19.1665V17.7526L16.545 14.1665H14Z"
                  fill="#F0F0F0"
                />
                <path
                  d="M14 14.1665L23 19.1665V17.7526L16.545 14.1665H14Z"
                  fill="#D80027"
                />
                <path
                  d="M4.05829 14.1664L0 16.421V14.1664H4.05829Z"
                  fill="#0052B4"
                />
                <path
                  d="M9.00002 14.8022V19.1664H1.14511L9.00002 14.8022Z"
                  fill="#0052B4"
                />
                <path
                  d="M6.45496 14.1665L0 17.7526V19.1665L9.00001 14.1665H6.45496Z"
                  fill="#D80027"
                />
                <path
                  d="M5.31044 8.83317L0 5.88288V8.83317H5.31044Z"
                  fill="#0052B4"
                />
                <path
                  d="M9.00001 8.83317L0 3.83318V5.24705L6.45496 8.83317H9.00001Z"
                  fill="#0052B4"
                />
                <path
                  d="M2.39731 3.83318L9.00002 7.50168V3.83318H2.39731Z"
                  fill="#0052B4"
                />
                <path
                  d="M9.00001 8.83317L0 3.83318V5.24705L6.45496 8.83317H9.00001Z"
                  fill="#F0F0F0"
                />
                <path
                  d="M9.00001 8.83317L0 3.83318V5.24705L6.45496 8.83317H9.00001Z"
                  fill="#D80027"
                />
                <path
                  d="M18.9417 8.83326L23 6.57863V8.83326H18.9417Z"
                  fill="#0052B4"
                />
                <path
                  d="M14 8.19743V3.83323H21.8549L14 8.19743Z"
                  fill="#0052B4"
                />
                <path
                  d="M16.545 8.83317L23 5.24705V3.83318L14 8.83317H16.545Z"
                  fill="#D80027"
                />
              </svg>
              <span className="pl-2">Engelstalig</span>
            </div>
            <StyledButton
              href={infographic.button.localFile.publicURL}
              target="_blank"
              rel="noopener noreferrer"
              className="d-flex justify-content-center text-center"
            >
              Download nu
            </StyledButton>
          </Box>
        </div>
      )}
    </div>
  </StyledMegaSubMenu>
)

export default MegaSubMenu
