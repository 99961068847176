/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import Next from 'img/icon/next.inline.svg'
import ButtonShell from './ButtonShell'

// Icons

const StyledButton = styled.div`
  background-color: ${props => props.theme.color.light};
  border: 3px solid ${props => props.theme.color.text.secondary};
  display: inline-block;
  border-radius: 30px;
  
  & > a, & > button, & > div {
    font-weight: ${props => props.theme.font.weight.l};
    color: ${props => props.theme.color.text.secondary};
    width: 100%;
    height: 100%;
    display: block;

    & > svg {
      margin-left: 15px;

      @media (min-width: 992px) {
        height: 25px;
        width: 25px;
      }

      @media (max-width: 991px) {
        height: 20px;
        width: 20px;
      }
    }

    &:hover {
      cursor: pointer;
    }

    @media (min-width: 992px) {
      font-size: ${props => props.small ? props.theme.font.size.m : props.theme.font.size.xm};
      padding: 12px 35px;
    }

    @media (max-width: 991px) {
      font-size: ${props => props.small ? props.theme.font.size.m : props.theme.font.size.m};
      padding: 10px 25px;
    }
  }
`

const StyledNext = styled(Next)`
  & path {
    fill: ${props => props.theme.color.secondary};
  }
`

const ButtonSecondary = ({ isAnchor, isCustom, to, children, className, type, small, onClick }) => {
  let icon = null

  switch (type) {
    case 'next':
      icon = <StyledNext />
      break;
  
    default:
      break;
  }

  let content = (
    <ButtonShell to={to} isAnchor={isAnchor}>
      {children}
      {icon}
    </ButtonShell>
  )

  if(isCustom) {
    content = (
      <div>
        {children}
        {icon}
      </div>
    )
  }

  if(onClick) {
    return (
      <StyledButton 
        role="button"
        className={className} 
        small={small}
        onClick={onClick}
      >
        {content}
      </StyledButton>
    )
  }

  return (
    <StyledButton className={className} small={small}>
      {content}
    </StyledButton>
  )
}

export default ButtonSecondary