import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import ImageBackground from 'components/elements/ImageBackground'

import menuBackground from 'img/menu.jpg'
import { Link } from 'gatsby'

const MenuBackground = styled(ImageBackground)`
  position: absolute !important;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
`

const MenuBackgroundOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.7);
`

const MenuBackgroundContent = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  padding: 75px 0 0 !important;

  & ul {
    margin: 0 auto !important;
  }

  & p {
    color: #fff !important;
    font-size: 20px !important;
  }
`

const StyledMotion = styled(motion.div)`
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
`

const Item = styled(motion.li)`

    @media (max-width: 991px){  
      width: 100%;
    }

  & > a {
    font-weight: ${props => props.theme.font.weight.s};
    color: ${props => props.theme.color.text.main};
    border: none !important;
    padding: 0 !important;
    font-size: 22px !important;

    @media (max-width: 991px){
      width: 100%;
    }

    @media (min-width: 992px) and (max-width: 1199px){
      font-size: ${props => props.theme.font.size.sm};
    }

    &:hover {
      text-decoration: underline;
    }

    &[aria-current] {
      font-weight: ${props => props.theme.font.weight.l};
      color: ${props => props.theme.color.text.contrast};
    }
  }
`

const StyledLink = styled(Link)`
  color: ${props => props.theme.color.text.light} !important;

  &:hover {
    text-decoration: underline;
  }

  border: 0 !important;
  font-size: 20px !important;
`

const CloseButton = styled.button`

  position: absolute;
  top: 10px;
  right: 10px;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before, &:after {
    background-color: ${props => props.theme.color.text.light};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const SubmenuMobile = ({ items, setIsOpen, isOpen, language }) => {

  const destinations = items.links[1].submenuDestinations
  const services = items.links[1].submenuServices

  return (
    <StyledMotion
      animate={isOpen ? 'open' : 'closed'}
      variants={{
        closed: {
          opacity: 0,
          y: -10,
          display: 'none',
        },
        open: {
          opacity: 1,
          y: 0,
          display: 'flex',
        },
      }}
    >
      <MenuBackground src={menuBackground} alt="Home">
        <MenuBackgroundOverlay />
        <MenuBackgroundContent>
          <CloseButton type="button" onClick={() => setIsOpen(!isOpen)}>
            <NavButtonCloseCross />
          </CloseButton>
          {language === 'nl_NL' ? (
            <p className="mb-1"><strong>Populaire bestemmingen</strong></p>
          ) : (
            <p className="mb-1"><strong>Top destinations</strong></p> 
          )}
          <ul>
            {destinations.map((item) => (
              <Item 
                initial="rest"
                whileHover="hover"
                animate="rest"
                key={item.link.url} 
                className="ml-xl-3 ml-lg-2 h-100 d-flex align-items-center"
              >
                <Link to={item.link.url}>{item.link.title}</Link>
              </Item>
            ))}
          </ul>
          <p>
            {language === 'nl_NL' ? (
              <StyledLink to="/offerte/"><strong>Andere bestemming? Vraag een prijsindicatie aan</strong></StyledLink>
            ) : (
              <StyledLink to="/en/quotation/"><strong>Other destination? Request a price indication</strong></StyledLink>
            )}        
          </p>
        <p className="mb-1"><strong>Services</strong></p>
          <ul>
            {services.map((item) => (
              <Item 
                initial="rest"
                whileHover="hover"
                animate="rest"
                key={item.link.url} 
                className="ml-xl-3 ml-lg-2 h-100 d-flex align-items-center"
              >
                <Link to={item.link.url}>{item.link.title}</Link>
              </Item>
            ))}
          </ul>
        </MenuBackgroundContent>
      </MenuBackground>
    </StyledMotion>
  )
}

export default SubmenuMobile
