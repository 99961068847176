/* eslint-disable import/no-unresolved */
import React from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { Link } from 'gatsby'

// Icons
import Truck from 'img/icon/delivery-truck.inline.svg'

const Button = styled(Link)`
  & > div {
    background-color: ${props => props.theme.color.secondary};
    border-radius: 30px;
    overflow: hidden;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${props => props.big ? '360px' : 'auto'};
  }

  
`

const Follower = styled(motion.div)`
  font-weight: ${props => props.theme.font.weight.l};
  color: ${props => props.theme.color.text.light};
  
  &:hover {
    text-decoration: underline;
  }

  @media (min-width: 992px) {
    font-size: ${props => props.small ? props.theme.font.size.m : props.theme.font.size.xm};
    padding: 12px 35px;
  }

  @media (max-width: 991px) {
    font-size: ${props => props.small ? props.theme.font.size.m : props.theme.font.size.m};
    padding: 10px 25px;
  }
`

const Leader = styled(motion.div)`
  & svg {
    height: 25px;
    width: 25px;
    margin-left: 15px;
  }
`

const ButtonAnimateTruck = ({ to, children, big }) => {
  return (
    <Button 
      to={to}
      big={big}
    >
      <motion.div
        initial="rest"
        whileHover="hover"
        animate="rest"
      >
        <Follower
          initial={{ x: -300 }}
          animate={{ x: 0 }}
          transition={{ duration: 1 }}
        >
          {children}
        </Follower>
        <Leader
          initial={{ x: -300 }}
          animate={{ x: 0 }}
          transition={{ duration: .9 }}
        >
          <motion.div 
            variants={{
              rest: {
                x: 0,
              },
              hover: {
                x: 150
              }
            }}
            transition={{ duration: .5 }}
          >
            <Truck />
          </motion.div>
        </Leader>
      </motion.div>
    </Button>
  )
}

export default ButtonAnimateTruck