/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

// Theme
import Theme from 'styles/Theme'

// Components
import Footer from './Footer'
import Header from './Header'

// CSS
import 'styles/all.scss'

const Content = styled.div`
  @media (min-width: 992px) {
    padding-top: 110px;
  }

  @media (max-width: 991px) {
    padding-top: 95px;
  }
`

const CookieLink = styled(Link)`
  color: ${(props) => props.theme.color.text.light};
  text-decoration: underline;
`

const Layout = ({ children, noFooter }) => {
  const [isSticky, setSticky] = useState(false)
  const [isScrollable, setIsScrollable] = useState(true)

  const handleScroll = () => {
    setSticky(window.scrollY > 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [handleScroll])

  return (
    <Theme isScrollable={isScrollable}>
      <Header isSticky={isSticky} setIsScrollable={setIsScrollable} />
      <Content isSticky={isSticky}>{children}</Content>
      <Footer noFooter={!noFooter} />
    </Theme>
  )
}

export default Layout
