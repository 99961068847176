/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { motion } from 'framer-motion'

// Language
import { getLanguage, setLanguage } from 'services/language'

const Switch = styled.button`
  color: ${props => props.theme.color.text.light};
`

const Option = styled.div`
  font-weight: ${props => props.isActive ? props.theme.font.weight.l : props.theme.font.weight.s};
  display: inline-block;
  vertical-align: top;
`

const Toggle = styled(motion.div)`
  background-color: ${props => props.theme.color.light};
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0 5px;
  width: 50px;
  height: 25px;
  border-radius: 25px;
`

const Circle = styled(motion.div)`
  background-color: ${props => props.theme.color.secondary};
  ${props => props.isLeft ? 'left: 4px' : 'right: 4px'};
  position: absolute;
  top: 3px;
  height: 19px;
  width: 19px;
  border-radius: 50%;
`

const LanguageSwitch = () => {
  const {
    pages,
    posts,
    site: { pathPrefix },
  } = useStaticQuery(graphql`
    {
      site {
        pathPrefix
      }
      pages: allWordpressPage {
        edges {
          node {
            path
            locale: wpml_current_locale
            translations: wpml_translations {
              locale
              path: href
            }
          }
        }
      }
      posts: allWordpressPost {
        edges {
          node {
            path
            locale: wpml_current_locale
            translations: wpml_translations {
              locale
              path: href
            }
          }
        }
      }
    }
  `)

  const language = getLanguage()

  let pathname = ''

  if (typeof window !== 'undefined') {
    pathname = window.location.pathname
  }

  if (pathPrefix) {
    pathname = pathname.replace(pathPrefix, '')
  }

  const allPages = [...posts.edges, ...pages.edges]

  let toPage = null

  allPages.forEach(({ node }) => {
    if (node.path === pathname) {
      // eslint-disable-next-line prefer-destructuring
      toPage = node.translations[0]
    }

    const translations = node.translations.find(({ path }) => path === pathname)

    if (translations && Object.keys(translations) > 0) {
      toPage = node
    }
  })

  const mainLanguage = language === 'nl_NL'

  if (!toPage) {
    toPage = mainLanguage ? { path: '/en/' } : { path: '/' }
  }

  if (toPage.path === '/en/sample-page/') {
    toPage.path = '/en/'
  }

  const switchLanguage = () => {
    setLanguage(toPage)
  }

  return (
    <Switch
      type="button"
      onClick={() => {
        switchLanguage(toPage)
      }}
    >
      <Option
        isActive={mainLanguage}
      >
        NL
      </Option>
      <Toggle
        initial="rest"
        whileHover="hover"
        animate="rest"
      >
        <Circle
          isLeft={mainLanguage}
          variants={{
            rest: {
              x: 0,
              opacity: 1
            },
            hover: {
              x: mainLanguage ? 22 : -22,
              opacity: .6
            }
          }}
        />
      </Toggle>
      <Option
        isActive={language === 'en_US'}
      >
        EN
      </Option>
    </Switch>
  )
}

export default LanguageSwitch
